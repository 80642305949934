.prod_fundo{
    height: 100%;
    width: 100%;

    display: flex;

    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 400;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

}

.prod_box{
 
    height: 90%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 2px;
}

/* BOX CAPA */
.prod_capa_box{

    height: 100%;
    width: 250px;
    padding: 2px;

}
.prod_capa{

    height: auto;
    margin-bottom: 5px;
    img{
        height: auto;
        width: 100%;
        border-radius: 6px;
    }
    border-radius: 6px;
}


.prod_aviso{

    padding: 2px;
    margin-top: 10px;
    height: 35px;
    background-color: rgb(93, 251, 159);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 5px;
}
.prod_valores{
    margin-top: 10px;
    height: 57px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px;
    border-radius: 5px;
}

.prod_btns{
    margin-top: 10px;
    height: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}
/* -------- */

.prod_infos_box{
    margin-top: -25px;
    padding: 20px;
    height: 100%;
    width: 100%;
 
}

.prod_titulo{

    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    font-weight: 600;
    font-size: 19px;
    overflow: hidden;
    gap: 15px;
}

.prod_sinop{
    margin-top: 10px;
    padding: 13px;
    background-color: white;
    border-radius: 5px;
}

.prod_infos{
    margin-top: 13px;
    height: auto;
    padding: 13px;
    overflow: hidden;
    background-color: white;
    border-radius: 5px;
}

.prod_infos_item{
    
}

.prod_cep_box{
    background-color: white;
    padding: 13px;
 
    margin-top: 13px;
    height: auto;

    gap: 15px;
    border-radius: 5px;

}

.prod_cep_btns{
 
    display: flex;
    gap: 15px;
    align-items: center;

}

.prod_cep_lista{
    margin-top: 7px;
}

.prod_link_voltar{
    margin-left: -50px;
    margin-top: -25px;
    margin-bottom: 20px;
    color: rgb(5, 28, 159);
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 595px){
    .prod_infos_box{
        margin-top: 0px;
        
     
    }

    .prod_titulo{
        width: 100%;
        justify-content: center;
    }

    .prod_link_voltar{
        margin-left: -50px;
        margin-top: 0px;
        margin-bottom: 14px;
        color: rgb(5, 28, 159);
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .prod_box{

        flex-direction: column;
        height: auto;
        width: 100%;
    }
    

}