.sidebar{
    margin: 0;
    padding: 0;
    width: 205px;

    background-color: #F5F5F5;
    position: fixed;
    height: 100%;
    overflow: auto;
    
    display: flex;
    flex-direction: column;
    
    z-index: 999;
}
/*
.sidebar div img{
    border-radius: 50%;
    display: block;
    margin: auto;
    width: 90px;
    height: 90px;
    filter: drop-shadow(2px, 3px, 6px, #121212);
    object-fit: cover;
}*/

.sibe-content{
    /* Img e info */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    min-height: 42px;

    background-color: #006dc0;

    background: url('../../assets/cover-c.jpg');
    background-size: cover;
    background-position: center;

    width: 100%;
    cursor: default;
}

.sibe-content-avatar{
    background-color: #08162e;
    width: 60px;
    height: 100%;
    justify-content: center;
    line-height: 0;
    cursor: pointer; 
    position: relative;
    display: flex;
    align-items: center;
}


.sibe-content-avatar img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: auto;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    transition: box-shadow .12s ease-out;
    padding: 6px;
   
}

.sibe-content-infos{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.sibe-content-infos-loja{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    color: #fff;

}


.sibe-menu{
 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-right: 1px solid rgba(0,0,0,.1);
    padding-top: 0.5em;
   /* background: #eee;*/
    background-color: rgba(255, 255, 255, .2);
    padding-bottom: 15em;
    overflow: hidden;

}

.sibe-menu-down{
    background-color: #FFFFFF;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.508);
}

.sibe-menu-down-a{
    color: rgb(52, 52, 52);
    margin-bottom: 10px;
}

.sibe-menu-down-b{
    color: rgb(12, 46, 83);
    font-size: 18px;
}


.sibe-menu-link{
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 2px;
    max-height: 2.875em;
    padding: 16px 0 16px 14px;
    text-decoration: none;
    color: #333;
    box-shadow: inset 0.25em 0 0 transparent;

   
    cursor: pointer;
}

.sibe-menu-link:hover{
   background-color: rgb(221, 220, 220);
   border-start-end-radius: 12px;
   border-end-end-radius: 12px;
   transition: .4s;
}

.sibe-menu-link-icon{

    justify-content: center;
    margin: 0 16px 0 0;
    width: 1.5em;
    height: 1.5em;
    align-items: center;
    display: flex;
    color: #333;
    cursor: pointer;
}

.sibe-menu-link-text{
    width: 100%;
    font-size: 14px;
   
    line-height: 1em;
    color: #333;
    cursor: pointer;

}

.sibe-fechado{
    transition: all 0.4s;
    height: 42px;
    overflow: hidden;
}




@media screen and (max-width: 595px){
    .sidebar{
        width: 115px;
    }
  
    .sibe-content-infos-loja{
        margin-left: 5px;
        white-space: break-spaces;
        overflow: auto;
        text-overflow: auto;
        font-size: 12px;     
    }

    .sibe-aberto{
        width: 210px;
    }

}