.modal{
    position: fixed;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;
    z-index: 1000;
    border-radius: 0;
    text-align: center;
    background: rgba(78, 84, 107, 0.879);

}

.modal-container{
    padding-top: 40px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    

    text-align: left;

    position: relative;
    outline: 0;
    width: auto;
    min-width: 350px;

    height: auto;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 30px auto;
    background-color: #ffffff;
    color: #333333;

    transition: all 0.6s;
}

.modal-btns{
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: end;
    margin: 10px;
    padding-right: 30px;
}


/* -----------------------  */

.modal-titulo{

    position: absolute;
    top: 0px;
    left: 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 10px;

    align-items: center;
    display: flex;

    height: 36px;
    width: 100%;
}

.close{
    background-color: #F65835;
    border: 0;

    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    border-top-left-radius: 3px;
    border-end-end-radius: 5px;
    height: 100%;
}

.close svg{
    margin-right: 4px;
    
}

.modal-titulo h2{
    margin: 0;
    padding: 0;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

/* -----------------------  */

.row{  
    padding-top: 6px;
    width: 100%;
    height: 100%;

}

.modal p{
    padding-top: .5em;
   white-space: pre-wrap; 
}

/* ------------------------------ */

.modal-box-small{
    display: inline-flex;
    flex-wrap: nowrap;
    width: 30%; 
   /* border: 1px solid #ff2525; */
}

.modal-box-small:first-child { margin-right: 2% !important; }
.modal-box-small:last-child { margin-left: 2% !important; }

.modal-box-medio{
    display: inline-flex;
    flex-wrap: nowrap;
    width: 48%; 
   /* border: 1px solid #ff2525; */
}

.modal-box-medio:first-child { margin-right: 2% !important; }

.modal-box-full{
  /*  border: 1px solid #4f35f6; */
    display: flex;
    width: 100%;

}



@media screen and (max-width: 560px) {
    .modal-container{
        border: 3px solid #F65835;
        width: 97% !important;
        max-width:97% !important;
    }
    
   
}