.container-center{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
}

.login{
    background-color: #EAEAEC;
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-area{
    display: flex;
    justify-content: center;
    background-color: #181c2e;
    width: 100%;
}

.login-area img{
    padding: 20px;
    margin-left: -20px;
    width: auto;
    height: 120px;
}

.login input{
    margin-bottom: 15px;
    height: 35px;
    border: 0;
    border-radius: 7px;
    padding: 10px;
    font-size: 15px;
    background-color: #fff;
}

form{
    margin-top: 1.5em;
    width: 90%;
    display: flex;
    flex-direction: column;
}

form h1{
    text-align: center;
    margin-bottom: 0.5em;
    color: #181c2e;
}

form button{
    height: 35px;
    border: 0;
    border-radius: 7px;
    background-color: #181c2e;
    color: #fff;
    font-size: 1.3em;
}

.login a{
    margin: 1.5em;
    color: black;
    cursor: pointer;
}