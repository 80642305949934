.selclie_box{
    height: auto;
    width: auto;
}


.selclie_box_infos{
    height: auto;
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    p{
        margin: 0;

    }
}

