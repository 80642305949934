.selend_content{
    box-sizing: border-box;
    padding: 0px;
    box-shadow: 0 8px 16px 0 rgb(187, 187, 187);
    background: #fff;

    width: 100%;
    height: auto;
    border-radius: 6px;

}

.selend_btn{
    padding: 6px;
    padding-left: 12px;
    box-sizing: border-box;
    min-height: 50px;
    
    &:not(.selend_selected):hover{
      cursor: pointer;     
      background-color: rgba(80, 179, 255, 0.131);  
    }

}

.selend_btn:first-of-type{
    border-start-start-radius: 6px;
    border-start-end-radius: 6px;
}

.selend_margin_btn{
    width: 105px;
    
}

.selend_bottom{
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 14px;
}

.selend_top{

    padding-top: 14px;
}

.selend_titulo{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selend_linha{
    color: dimgray;
}

.selend_buttons{
    display: flex;
    gap: 5px;
}

.selend_duplo_buttons{
    width: 100%;
   
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
}



.selend_selected{
    cursor: pointer;
    background-color: rgba(29, 132, 25, 0.357);
}







