.amarelo{
    color: red;
}

.dash_fundo{
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;


    width: 100%;
   /* border: 1px solid red; */ 
    padding: 25px;
    overflow: auto;

}

.dash_content{
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 595px){
    
    .dash_fundo{
        padding: 2px;   
    }

}