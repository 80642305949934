
.fundo_seletor{
    height: 400px;
}

.seletor_lista{
    margin: 5px;
    padding: 5px;

    overflow: auto;
    height: 92%;
    
}

.seletor_lista_item{
    border-bottom: 1px solid rgb(209, 216, 209);

    height: 42px;

    display: flex;

    cursor: pointer;

    p{
        width: 185px;
        margin-top: 2px;
        margin-left: 4px;
        padding: 4px;

        
        /* Centraliza o P verticalmente */
        display: flex;
        justify-content: center;
        flex-direction: column;
        /* --- */
    }



}

.seletor_lista_item:hover{
    background-color: rgb(204, 227, 239);
}

.seletor_top{
    margin-top: -7px;
}