.title-title{
    background-color: #0a0a0a;
    color: white!important;
    height: 55px;

    padding-left: 125px;
    padding-right: 125px;
 
    display: flex;
    justify-content: space-between;

}

.title-dados{
    color: white!important;
    height: 100%;
    width: 250px;
    max-width: 250px;
    display: flex;
    align-items: center;
   
    gap: 10px; 
    padding-left: 15px;  
    white-space: nowrap;
}

.title-title-user{
    
  
    width: 250px;
    max-width: 250px;
    height: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 18px;
    font-weight: 600;
    gap: 12px;

}


.menu-user-fundo{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1000;
}

.menu-user-box{
    position: fixed;
    height: 250px;

    background-color: rgb(255, 255, 255);

    inset: 48px 7px auto auto;

    width: 320px;
    max-width: 100vw;

    box-shadow: 0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%);

    border: 0;
    font: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;

    display: flex;
    flex-direction: column;

}

.menu-user-infos{
    height: 100%;
    width: 100%;
    display: flex;
    gap: 25px;
    padding: 25px;
    justify-content: center;
}

.menu-user-btns{
    height: 75px;
    width: 100%;
    background-color: rgb(236, 236, 236);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}


.title-cart{

    width: 120px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    cursor: pointer;
}





@media screen and (max-width: 595px){
    .title-title{
        padding-left: 0;
        padding-right: 0;
    }

    .title-dados{
        width: auto;
        max-width: auto;
    }
    
    .title-title-user{      
        width: auto;
        max-width: auto;
    }
}