.cad-pe-content{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
 

    max-width: 580px;
    
}        

.cad-pe-infos-col{
    background-color: rgba(243, 243, 243, 0.85);
    border: 1px solid rgb(192, 192, 192);
    border-radius: 6px;
    flex: 1 300px;   /* Initial width of 600px/2
    Grow to fill remaining space */
    min-width: 300px;    /* No minimal width */

    padding: 5px;
    padding-top: 10px;

    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;

}

.cad-pe-infos-col-title{
    border-start-start-radius: 6px;
    border-start-end-radius: 6px;
  /*  border: 1px solid springgreen; */
    background-color: rgb(228, 228, 228);
    height: 36px;
    width: 100%;
    margin-bottom: 6px;

}

.cad-pe-infos-col-title div{
    color: rgb(0, 0, 0);
    width: 100%;
    margin-top: 10px;
    text-align: center;


}

@media screen and (max-width: 600px){

    .cad-pe-content{
        display: block;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        margin: auto;
        padding-top: 5px;
        width: 100%;
        
    }    
    
    .cad-pe-infos-col{
        margin-right: 0;
    
    }
}