.prods_fundo{
    height: 100%;
    width: 100%;
  /*  background-color: rgb(255, 255, 255); */
  display: flex;
  gap: 17px;
  justify-content: center;
  flex-wrap: wrap;
  
}

.prods_item_box{
    border: 1px solid rgb(208, 208, 208);
    height: 362px;
    width: 230px;
    padding: 5px;
    cursor: pointer;
    border-radius: 7px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.prods_item_box:hover{
    height: 393px;
}

.prods_item_capa{
    /*border: 1px solid rgb(23, 23, 197);*/
    height: 200px;
    z-index: 0;
    position: relative;
    img{
        width: 100%;
        border-radius: 5px;      
    }
}

.prods_item_capa:hover{
     
        z-index: 999;
}


.prods_item_titulo{
    background-color: rgba(255, 255, 255, 0.798);
    height: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    font-weight: 600;
    font-size: 14px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.prods_item_infos{
    background-color: rgba(255, 255, 255, 0.798);
    position: relative;
    height: auto;
    padding: 5px;
    overflow: hidden;
    z-index: 2;
}
.prods_item_valores{
    position: relative;
    height: 57px;
    min-height: 57px;
    background-color: rgba(255, 255, 255, 0.859);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px;
    z-index: 3;
}


@media screen and (max-width: 595px){
    .prods_item_box{
        width: 85%;
    }
}